<!-- 搜索列表页面 -->
<template>
    <div>
       <el-card class="Searchs centebox" shadow="never">
           <div class="search-but">
               <div class="input-suffix fl">
                   <el-input placeholder="请输入内容" clearable class="text" v-model="searchText"></el-input>
               </div>
               <el-button class="but search fr" type="primary" icon="el-icon-search" @click="search(1)"></el-button>
           </div>

           <div class="search-but" style="width: 1200px;position: fixed;top: 95px;right: 50%;margin: 0 -600px 0 0;">
               <el-button class="but fr search" type="primary" icon="el-icon-search" @click="search2()"></el-button>
               <div class="input-suffix fr" style="width: 245px;margin: 0 5px 0 0;">
                   <el-input placeholder="请输入内容" clearable class="text" v-model="searchText2" ></el-input>
               </div>

           </div>

           <div class="dess">
<!--               当前搜索:-->
<!--               <span>千人计划</span> 共有-->
<!--               <span>24</span> 条相关记录-->
           </div>

           <ul :v-model="tableData">
               <li v-for="(item,i) in tableData" :name="i" :key="i">
                   <p>{{  item.title }}</p>
                   <span>{{ item.createTime }}</span>
                   <router-link :to="{name: 'noticed', params:{id:item.id}}" class="fr">查看更多>></router-link>
               </li>
           </ul>

           <el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize"
                          @current-change="requestPage" id="pagination"/>
       </el-card>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                searchText: "",
                searchText2: "",
                tableData: [],
                page: 1,
                pages: 0,
                pageSize: 5,
                total: 0,
                loading:false,
                multipleTable:[],
                form: {
                    columnId: 0,
                    title: '',
                    type: '',
                    status: 0,
                    content: '',
                },
            };
        },
        methods: {
            getData() {
                let self = this;
                self.$axios.get('/article/search', {
                    params: {
                        current: 1,
                        size:4,
                        keyword: self.searchText ,
                        status: 1,
                    }
                }).then(response => {
                  self.searchText = this.$route.query.searchText
                  self.search()
                  self.tableData = response.data.data.records;
                    self.total = response.data.data.total
                }).catch(error => {
                    console.error(error)
                })
            },
            
            //  分页
            requestPage(value) {
                let self = this
                self.$axios.get('/article/search', {
                    params: {
                        current: value,
                        keyword: self.searchText ,
                        size:4,
                        status: 1,
                    }
                }).then(response => {
                    self.tableData = response.data.data.records;
                    self.total = response.data.data.total
                }).catch(error => {
                    console.error(error)
                })
            },

            handleChange(val) {
            },
            //  查询
            search(value) {
                let self = this
                self.$axios.get('/article/search', {
                    params: {
                        current: value,
                        size:4,
                        keyword: self.searchText ,
                        status: 1,
                    }
                }).then(response => {
                    self.tableData = response.data.data.records;
                    self.total = response.data.data.total

                }).catch(error => {
                    console.error(error)
                })
            },
            search2(){
                this.searchText = this.searchText2
                this.search(1)
                this.searchText2=''
            }
        },
        
        created() {
            this.getData();
        }
    };
</script>

<style lang="less" scoped>
    .Searchs {
        position: relative;
        background: #fff;
        padding: 50px 0 0 0;

        .search-but {
            width: 600px;
            overflow: hidden;
            margin: auto;

            .input-suffix {
                width: 545px;

                .text {
                    input {
                        background: none;
                    }
                }
            }

            .but {
                width: 50px;
            }
        }

        .dess {
            padding: 50px 0 0 0;
            margin: 0 auto;
            width: 1150px;
            text-align: left;
            border-bottom: #ddd solid 1px;
            height: 34px;

            span {
                color: #fb1d1d;
                /*padding: 0 5px;*/
            }
        }

        ul {
            margin: auto;
            width: 96%;
            padding: 0 24px;

            li {
                text-align: left;
                padding: 4px 16px;
                border-bottom: #ddd solid 1px;

                p {
                    color: #1d72c1;
                }

                span {
                    font-size: 14px;
                }

                a {
                    text-decoration: none;
                    font-size: 14px;
                    color: #2380d7;
                }
            }
        }
    }
    #pagination {
        margin: 24px 0 0 8px;
    }
    .search {
      padding-left: 18px;
    }
</style>